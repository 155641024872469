<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <a-config-provider :locale="locale">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </a-config-provider>
    <a-config-provider :locale="locale">
      <keep-alive include="index,aiindex,map,plist,ai">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </a-config-provider>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN
    };
  }
  // components: {
  //   HelloWorld
  // }
};
</script>