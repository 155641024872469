import Vue from 'vue';
import router from './router'
import 'babel-polyfill'
import Antd from 'ant-design-vue';
import App from './App';
import store from './store/index';
// import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less';
import './assets/css/main.css';
Vue.config.productionTip = false;

Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
