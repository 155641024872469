import { state } from './state'
import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex);
export default new Vuex.Store({
  state: state,
  mutations: {
  },
  actions: {
  },
  getters: {
    showNum: state => {
      return '当前Count最新的数据为: '+ state.count 
    }
  },
  modules: {
  }
})