import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);


export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        // {
        //     path: '/',
        //     redirect: '/myuserinfo'
        // },
        // {
        //     path: '/index',
        //     name: 'index',
        //     component: () => import('../page/Index.vue'),
        //     meta: { title: '首页文件', keepAlive: false }
        // },


        {
            path: '/login',
            name: 'login',
            component: () => import('../page/Login.vue'),
            meta: { title: '登录', keepAlive: false }
        },




        {
            path: '/classmag/kind',
            name: 'classmagkind',
            component: () => import('../page/classKind.vue'),
            meta: { title: '种类管理', keepAlive: false }
        },

        {
            path: '/teammag/list',
            component: () => import('../page/TeamMag.vue'),
            meta: { title: '管理员管理', keepAlive: false }
        },

        {
            path: '/basemag',
            component: () => import('../page/BaseMag.vue'),
            meta: { title: '基础设置', keepAlive: false }
        },

        {
            path: '/baseenmag',
            component: () => import('../page/BaseEnMag.vue'),
            meta: { title: '英文基础设置', keepAlive: false }
        },






        
        {
            path: '/casemag',
            name: 'casemag',
            component: () => import('../page/CaseList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },
        {
            path: '/awardsmag',
            name: 'awardsmag',
            component: () => import('../page/AwardsList.vue'),
            meta: { title: '获奖管理', keepAlive: false }
        },
        {
            path: '/friendmag',
            name: 'friendmag',
            component: () => import('../page/FriendList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },
        {
            path: '/friendhmag',
            name: 'friendhmag',
            component: () => import('../page/FriendHList.vue'),
            meta: { title: '案例列表', keepAlive: false }
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('../page/Index.vue'),
            meta: { title: '首页', keepAlive: true }
        },
        {
            path: '/index/:lag',
            name: 'index',
            component: () => import('../page/Index.vue'),
            meta: { title: '首页', keepAlive: true }
        },
        {
            path: '/pdetall/:id',
            name: 'index',
            component: () => import('../page/Pdetall.vue'),
            meta: { title: '案例', keepAlive: false }
        },
        {
            path: '/pdetall/:id/:lag',
            name: 'index',
            component: () => import('../page/Pdetall.vue'),
            meta: { title: '案例', keepAlive: false }
        },


        
        
        {
            path: '/adetall/:id',
            name: 'index',
            component: () => import('../page/Adetall.vue'),
            meta: { title: '荣誉', keepAlive: false }
        },
        {
            path: '/adetall/:id/:lag',
            name: 'index',
            component: () => import('../page/Adetall.vue'),
            meta: { title: '荣誉', keepAlive: false }
        },




        {
            path: '/plist/:from',
            name: 'index',
            component: () => import('../page/Plist.vue'),
            meta: { title: '案例', keepAlive: true }
        },
        {
            path: '/plist/:from/:lag',
            name: 'index',
            component: () => import('../page/Plist.vue'),
            meta: { title: '案例', keepAlive: true }
        },


        {
            path: '/tech',
            name: 'tech',
            component: () => import('../page/AiPage.vue'),
            meta: { title: 'TECH', keepAlive: true }
        },
        {
            path: '/tech/:lag',
            name: 'tech',
            component: () => import('../page/AiPage.vue'),
            meta: { title: 'TECH', keepAlive: true }
        },
        {
            path: '/clist',
            name: 'clist',
            component: () => import('../page/Clist.vue'),
            meta: { title: 'AI', keepAlive: false }
        },
        {
            path: '/mindex',
            name: 'mindex',
            component: () => import('../page/mindex.vue'),
            meta: { title: 'AI', keepAlive: false }
        },
        {
            path: '/aimindex',
            name: 'aimindex',
            component: () => import('../page/aimindex.vue'),
            meta: { title: 'AI', keepAlive: false }
        },
    ]
});
